.root {
  padding: var(--space-xl-2xl) var(--layout-gutter) var(--space-xl-2xl)
    var(--layout-gutter);
  background: var(--color-background-default);
  color: var(--color-text-default);
}

.logoColumn {
  margin-bottom: var(--space-xl);
}

.logo {
  width: 150px;
  height: auto;
  color: var(--color-text-tertiary);
}

.navPrimary {
  font-size: var(--text-body-s);
}

.navPrimary a,
.navSecondary a {
  color: inherit;
  font: inherit;
  text-decoration: none;

  &:hover {
    color: var(--color-accent-default);
  }
}

.navPrimaryList {
  column-gap: var(--column-gutter);
  column-width: 150px;
}

.navPrimaryListItem {
  margin-bottom: var(--space-m);
  font-family: var(--font-family-headline);
  break-inside: avoid-column;
}

.navSecondaryList {
  margin-top: 0.25em;
}

.navSecondaryListItem {
  color: var(--color-text-secondary);
  font-family: var(--font-family-body);
}

.navSecondaryListItem + .navSecondaryListItem {
  margin-top: 0.25em;
}

.bottomFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: var(--space-xl);
  border-top: 1px solid var(--color-border);
}

.socialLinks {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.socialLink {
  color: var(--color-text-tertiary);

  &:hover {
    color: var(--color-text-default);
  }
}

.socialLinkIcon {
  width: 2em;
  height: 2em;
}

.copyRight {
  color: var(--color-text-tertiary);
  font-size: var(--text-body-xs);
}

/* RESPONSIVE */
@media (--medium) {
  .bottomFooter {
    gap: var(--column-gutter);
    justify-content: flex-start;

    & > * {
      flex: 1;
    }
  }
}

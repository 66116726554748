/* BODY FONT STYLES */
.bodyXS {
  font-family: var(--font-family-body);
  font-size: var(--text-body-xs);
  letter-spacing: 0.015em;
  line-height: 1.5;
}

.bodyS {
  font-family: var(--font-family-body);
  font-size: var(--text-body-s);
  letter-spacing: 0.0075em;
  line-height: 1.5;
}

.bodyM {
  font-family: var(--font-family-body);
  font-size: var(--text-body-m);
  letter-spacing: 0.005em;
  line-height: 1.55;
}

.bodyL {
  font-family: var(--font-family-body);
  font-size: var(--text-body-l);
  letter-spacing: 0;
  line-height: 1.5;
}

.bodyXL {
  font-family: var(--font-family-body);
  font-size: var(--text-body-xl);
  letter-spacing: 0;
  line-height: 1.35;
}

/* HEADLINE FONT STYLES */
.headlineXS {
  font-family: var(--font-family-headline);
  font-size: var(--text-headline-xs);
  letter-spacing: 0;
  line-height: 1.3;
}

.headlineS {
  font-family: var(--font-family-headline);
  font-size: var(--text-headline-s);
  letter-spacing: 0;
  line-height: 1.3;
}

.headlineM {
  font-family: var(--font-family-headline);
  font-size: var(--text-headline-m);
  letter-spacing: -0.0025em;
  line-height: 1.2;
}

.headlineL {
  font-family: var(--font-family-headline);
  font-size: var(--text-headline-l);
  letter-spacing: -0.005em;
  line-height: 1.15;
}

.headlineXL {
  font-family: var(--font-family-headline);
  font-size: var(--text-headline-xl);
  letter-spacing: -0.02em;
  line-height: 1.05;
}

.headline2XL {
  font-family: var(--font-family-headline);
  font-size: var(--text-headline-2xl);
  letter-spacing: -0.015em;
  line-height: 0.95;
}

/* INTRO TEXT STYLES */
.titleL {
  font-family: var(--font-family-headline);
  font-size: var(--text-headline-m);
  letter-spacing: 0;
  line-height: 1.3;
}

/* EYEBROW STYLES */
.eyebrow {
  margin-bottom: var(--space-2xs-xs);
  color: var(--color-text-secondary);
  font-family: var(--font-family-mono);
  font-size: var(--text-body-xs);
  letter-spacing: 0.05em;
  line-height: 1;
  text-transform: uppercase;
  white-space: nowrap;

  & a {
    color: inherit;
    text-decoration: none;
  }
}

.eyebrowSmall {
  margin-bottom: var(--space-2xs-xs);
  color: var(--color-text-secondary);
  font-family: var(--font-family-mono);
  font-size: var(--text-body-2xs);
  letter-spacing: 0.065em;
  line-height: 1;
  text-transform: uppercase;
  white-space: nowrap;
}

.light {
  --color-background-default: var(--base-color-grey-100);
  --color-background-light: var(--base-color-white);
}

.dark {
  --color-background-default: var(--base-color-grey-1000);
  --color-background-light: var(--base-color-grey-800);
  --color-text-default: var(--base-color-white);
  --color-background-card: transparent;
  --color-text-secondary: rgb(255 255 255 / 80%);
  --color-text-tertiary: rgb(255 255 255 / 65%);
  --color-border: rgb(255 255 255 / 15%);
  --color-text-on-color: var(--base-color-grey-100);
}

.purple {
  --color-accent-default: var(--base-color-purple-500);
  --color-accent-hover: var(--base-color-purple-600);
  --color-accent-subdued: var(--base-color-purple-100);
  --color-border-accent: var(--base-color-purple-200);
}
